<template>
 <div> 
   <!--Main Content section START-->
 <section class="mainsc_content contact-main-section">
   <div class="container">

      <div class="contact-content">  
          <h1>{{$t('contactUs.title')}}</h1> 
          <hr/>

         <div class="formstart-sc">
           <!--form step start-->
        <form ref="form" @submit.prevent="sendEmail"> 
          <div  class="step-sc-2">
            <h2>{{$t('contactUs.formTitle')}}</h2>     
              <b-modal id="successMessage" size="sm" centered title="" hide-footer hide-header>
                       <p class="text-center checkmarksc"><img src="@/assets/images/ok.png" alt=""></p>
                       <h3 class="text-center oksc">{{$t('contactUs.success.title')}}</h3>
                      <p class="text-center okscde">{{$t('contactUs.success.subTitle')}}</p>
                       <b-button class="closesuccessbtn" block @click="$bvModal.hide('successMessage')">{{$t('contactUs.success.btn')}}</b-button>
                  </b-modal>
            <div class="row"> 
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="fullName"> {{$t('contactUs.formContent.name')}}</label>
                        <input
                          type="text" name="fullName"
                          required
                         :placeholder="$t('contactUs.formContent.namePlaceholder')" 
                          v-model="formData.fullName"
                        > 
                      </div>
                </div>
                <div class="col-md-6">
                      <div class="form-group">
                        <label for="email"> {{$t('contactUs.formContent.email')}}</label>
                        <input
                          type="email" name="email"
                          required
                          :placeholder="$t('contactUs.formContent.emailPlaceholder')" 
                          v-model="formData.email"
                        > 
                      </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      <label for="companyName">{{$t('contactUs.formContent.message')}}</label>
                      <textarea    name="yourMessage"
                      required
                      v-model="formData.yourMessage" 
                      :placeholder="$t('contactUs.formContent.messagePlaceholder')"  ></textarea>  
                    </div> 
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="text-center screcp"> 
                      <vue-recaptcha 
                        ref="recaptcha" 
                        sitekey="6Ldzr8gdAAAAACPjo3-GpynmSV4CeU_Nae4ILfzz"  
                    /></div>
                  </div>
                </div>
                <div class="row"> 
                  <div class="col-md-12 text-sc-center"> 
                    <input type="submit"  
                    :value="$t('contactUs.formContent.btn')"
                    class="scbtn-submit"></div>
                </div>
            </div>      
          </div> 
          
          </form>
         
         </div> <!--END-->
      </div>

   </div>
 </section>
 <!--END main section--> 

 </div>
</template>

<script>
import MainPage from '@/layouts/Main_Layout.vue' 
 import { VueRecaptcha } from 'vue-recaptcha';
 import emailjs from 'emailjs-com';
export default {
  components: { VueRecaptcha },
  //this for layout call
        created() {
            this.$parent.$emit('update:layout', MainPage);
        },
        //this for layout render
        render() {
            return this.$slots.default[0];
        }, 
    data() {
    return {
      //step:1,
      formData:{ 
        fullName:null,
        email:null,         
        yourMessage:null,
        recaptcha:null,
      }
    }
  },
  //Meta info title + description + others meta
    metaInfo() {
          return {  
                title: this.$t('metaInfo.contactTitle'),
                 htmlAttrs: {
                  lang: this.$i18n.locale,
                },
                meta: [
                    { name: 'description', content:  this.$t('metaInfo.contactDesc')}  
                ] 
          }
      },
     //end meta info
  methods:{
    onEvent() {
        // when you need a reCAPTCHA challenge
        this.$refs.recaptcha.execute();
      }, 
    
    sendEmail() {
         
       emailjs.sendForm('service_nats6lf', 'template_pvbh27d', this.$refs.form, 'user_QT2clRYi5LsQlkf4atCDc')  
        .then((result) => {  
            console.log('SUCCESS!', result.text); 
            this.formData.fullName=this.formData.email=this.formData.yourMessage=''
            this.$bvModal.show('successMessage'); 
            
        }, (error) => {
            console.log('FAILED...', error.text);
             alert('Oops! Something went wrong and we could not send your message.')
        }); 
        
    }
  }
};
</script>

<style lang="scss" scoped>

</style>